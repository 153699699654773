import react, { useState, useEffect } from 'react'
import { BarChart, Bar, Pie, PieChart, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';
import moment from 'moment';
import NodataImage from '../../../../../images/nodata.png';
import PopUp from "../../../../presentationals/Popup/Popup.component";
import Loader from '../../../../presentationals/Loader/Loader.component';
import { getPaymentInsightsDashboardapi } from '../../AdminActionCreator.component';


const handleDateRange = (filter, dateType) => {
    const today = moment();
    let sDate, eDate;

    if (filter === 'today') {
        eDate = today.format('DD-MM-YYYY');
        sDate = today.subtract(1, 'days').format('DD-MM-YYYY');
    } else if (filter === 'lastWeek') {
        eDate = today.format('DD-MM-YYYY');
        sDate = today.subtract(7, 'days').format('DD-MM-YYYY');
    } else if (filter === 'lastMonth') {
        eDate = today.format('DD-MM-YYYY');
        sDate = today.subtract(30, 'days').format('DD-MM-YYYY');
    } else if (filter === 'custom') {
        eDate = today.format('YYYY-MM-DD');
        sDate = today.subtract(1, 'days').format('YYYY-MM-DD');
    } else {
        sDate = '';
        eDate = '';
    }
    return dateType === 'fromDate' ? sDate : eDate;
}

const groupByDate = (data) => {
    return data.reduce((acc, current) => {
        if (!acc[current.date]) {
            acc[current.date] = [];
        }
        acc[current.date].push(current);
        return acc;
    }, {});
};

export default function PaymentInsightsMain() {
    const PieCOLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
    const [weeklyCollectionData, setWeeklyCollectionData] = useState([])
    const [paymentOverViewData, setpaymentOverViewData] = useState([])
    const [selectValue, setSelectValue] = useState('lastWeek')
    const [mandateInsightTab, setMandateInsightTab] = useState('RazorPay')
    const [APIData, setAPIDate] = useState({})
    const [MandateInsightSuccessData, setMandateInsightSuccessData] = useState([])
    const [MandateInsightFailureData, setMandateInsightFailureData] = useState([])
    const [paymentChannelInsightsData, setpaymentChannelInsightsData] = useState([])
    const [paymentChannelAggregatedInsightsData, setpaymentChannelAggregatedInsightsData] = useState([])
    const [paymentChannelInsightsGroupData, setpaymentChannelInsightsGroupData] = useState([])
    const [fromDate, setFromDate] = useState(handleDateRange('lastWeek', 'fromDate'))
    const [popup, setPopup] = useState(false)
    const [loader, setLoader] = useState(false)
    const [toDate, setToDate] = useState(handleDateRange('lastWeek', 'toDate'))

    const handleChange = (e, type) => {
        if (type === 'selectBox') {
            const selectedFilter = e.target.value;
            setSelectValue(selectedFilter);
            setFromDate(handleDateRange(selectedFilter, 'fromDate'));
            setToDate(handleDateRange(selectedFilter, 'toDate'));

        } else if (type === 'fromDate') {
            setFromDate(e.target.value);
        } else if (type === 'toDate') {
            setToDate(e.target.value);
        } else if (type === 'apply') {
            HandleAPI()
        }

    }

    useEffect(() => {
        HandleAPI()
    }, [selectValue])


    const HandleAPI = () => {
        let sDate, eDate;

        if (selectValue === 'custom') {
            const startDate = moment(fromDate);
            const endDate = moment(toDate);

            const diffMonths = endDate.diff(startDate, 'months');
            console.log(diffMonths)
            if (diffMonths >= 1) {
                setPopup(true)
                removePopup()
                return
                // sDate = handleDateRange('today', 'fromDate');
                // eDate = handleDateRange('today', 'toDate');
            } else {
                sDate = startDate.format('DD-MM-YYYY');
                eDate = endDate.format('DD-MM-YYYY');
            }
        } else {
            sDate = fromDate
            eDate = toDate
        }
        console.log(sDate, '-', eDate)
        setLoader(true)
        getPaymentInsightsDashboardapi(sDate, eDate, (callback) => {
            setLoader(false)
            console.log(callback.weeklyPaymentInsights)
            console.log("done")
            setWeeklyCollectionData(callback?.weeklyPaymentInsights || [])
            setpaymentOverViewData(callback?.paymentOverviewData)
            setpaymentChannelInsightsData(callback?.paymentChannelInsights || [])
            setpaymentChannelInsightsGroupData(groupByDate(callback?.paymentChannelInsights?.paymentChannelInsights) || [])
            setpaymentChannelAggregatedInsightsData(callback?.paymentChannelInsights?.paymentChannelInsightAggregateData || [])
            setAPIDate(callback)
            setMandateInsightTab('')
        })
    }

    const closePopup = () => {
        setPopup(false)
    }
    const removePopup = () => {
        setTimeout(() => {
            setPopup(false)
        }, 5000)
    }

    const handleMandateInsight = (value) => {
        setMandateInsightTab(value === mandateInsightTab ? '' : value)
        // const [MandateInsightSuccessData, setMandateInsightSuccessData] = useState([])
        // const [MandateInsightFailureData, setMandateInsightFailureData] = useState([])
        if (value === 'RazorPay') {
            setMandateInsightSuccessData(APIData.mandateInsightsRazorpaySuccess || [])
            setMandateInsightFailureData(APIData.mandateInsightsRazorpayFailure || [])
        } else if (value === 'BillDesk') {
            setMandateInsightSuccessData(APIData.mandateInsightsBillDeskSuccess || [])
            setMandateInsightFailureData(APIData.mandateInsightsBillDeskFailure || [])

        } else {
            setMandateInsightSuccessData([])
            setMandateInsightFailureData([])
        }
    }
    return (
        <>
            <div className="paymentInsightsMain">

                {popup ?
                    <PopUp closePopup={closePopup} popupStatus={'Data can\'t be Fetched for More Than 1 Month.'} /> : ""}
                {loader ? <Loader /> : ""}
                <div className="container-fluid">
                    <div className="heading">
                        <h3>Payment Insights</h3>
                    </div>
                    <div className="filters">
                        <div className="row">
                            <div className="col-sm-2 col-xs-12">
                                <div className="inputGrp">
                                    <label htmlFor='selectBox' className='labelTag'><i class="fa fa-filter" aria-hidden="true"></i> Filter</label>
                                    <select className='form-control' value={selectValue} id="selectBox" onChange={(e) => handleChange(e, 'selectBox')}>
                                        <option value="today">Today</option>
                                        <option value="lastWeek">Last Week</option>
                                        <option value="lastMonth">Last Month</option>
                                        <option value="custom">Custom</option>
                                    </select>
                                </div>
                            </div>
                            {
                                selectValue === 'custom' ?
                                    <>

                                        <div className="col-sm-2 col-xs-12">
                                            <div className="inputGrp">
                                                <label htmlFor='fromDate' className='labelTag'>From</label>
                                                <input className='form-control' type="date" value={fromDate} id="fromDate" onChange={(e) => handleChange(e, 'fromDate')} />
                                            </div>
                                        </div>
                                        <div className="col-sm-2 col-xs-12">
                                            <div className="inputGrp">
                                                <label htmlFor='toDate' className='labelTag'>To</label>
                                                <input className='form-control' type="date" value={toDate} id="toDate" onChange={(e) => handleChange(e, 'toDate')} max={handleDateRange('custom', 'toDate')} />
                                            </div>
                                        </div>
                                        <div className="col-sm-2 col-xs-12">
                                            <div className="inputGrp" style={{ marginTop: '24px' }}>
                                                <button className='applyBtn' onClick={(e) => handleChange(e, 'apply')}>Apply Filter</button>
                                            </div>
                                        </div>
                                    </>
                                    : ''}
                        </div>
                    </div>
                    {
                        APIData ?
                            <>
                                <div className="paymentOverviewSection outerBoxWithPadding">
                                    <div className="heading">
                                        <h4>Payment Overview</h4>
                                    </div>
                                    <div className="PaymentBoxes">
                                        <div className="tabs">
                                            <div className="subtabs" style={{backgroundColor:'#ffe2e6',height:window.innerWidth < 1200 ? '158px' : '144px'}}>
                                            <div style={{display:'flex',justifyContent:'space-between',alignItems:'basline'}}>
                                            <div className="circlesIcon"style={{backgroundColor:'#fa5a7d'}}><i class="fa fa-cart-arrow-down" aria-hidden="true"></i></div>
                                            <h5 className="bolder-font">{(paymentOverViewData?.receivableEmiCount || 0)?.toLocaleString("en-US")}</h5>
                                          
                                            </div>
                                            <h4>{'₹ ' + (paymentOverViewData?.receivableEmiSum || 0)?.toLocaleString("en-US")}</h4>
                                           
                                            <h5>Recievable EMI</h5>
                                            </div>
                                        </div>
                                        <div className="tabs">
                                            <div className="subtabs" style={{backgroundColor:'#fff4de',height:window.innerWidth < 1200 ? '158px' : '144px'}}>
                                            <div style={{display:'flex',justifyContent:'space-between',alignItems:'basline'}}>
                                            <div className="circlesIcon" style={{backgroundColor:'#ff947a'}}><i class="fa fa-money" aria-hidden="true"></i></div>
                                            <h5 className="bolder-font">{(paymentOverViewData?.paymentReceivedCount  || 0)?.toLocaleString("en-US")}</h5>
                                            
                                            </div>
                                            <h4>{'₹ ' + (paymentOverViewData?.paymentReceivedSum  || 0)?.toLocaleString("en-US")}</h4>
                                            
                                            <h5>Collected Amount(Current Date)</h5>
                                            </div>
                                        </div>
                                        <div className="tabs">
                                            <div className="subtabs" style={{backgroundColor:'#fff1c1',height:window.innerWidth < 1200 ? '158px' : '144px'}}>
                                            <div style={{display:'flex',justifyContent:'space-between',alignItems:'basline'}}>
                                            <div className="circlesIcon" style={{backgroundColor:'#dbb328'}}><i class="fa fa-money" aria-hidden="true"></i></div>
                                            <h5 className="bolder-font">{(paymentOverViewData?.paymentReceivedByDueDateCount  || 0)?.toLocaleString("en-US")}</h5>
                                            
                                            </div>
                                            <h4>{'₹ ' + (paymentOverViewData?.paymentReceivedByDueDateSum  || 0)?.toLocaleString("en-US") }</h4>
                                          
                                            <h5>Collected Amount(By Due Date)</h5>
                                            </div>
                                        </div>
                                        <div className="tabs">
                                            <div className="subtabs"  style={{backgroundColor:'#dcfce7',height:window.innerWidth < 1200 ? '158px' : '144px'}}>
                                            <div style={{display:'flex',justifyContent:'space-between',alignItems:'basline'}}>
                                            <div className="circlesIcon" style={{backgroundColor:'#3dd856'}}><i class="fa fa-credit-card-alt" aria-hidden="true"></i></div>
                                            <h5 className="bolder-font">{(paymentOverViewData?.razorpayMandateCreatedCount  || 0)?.toLocaleString("en-US")}</h5>
                                            </div>
                                            <h4>{'₹ ' + (paymentOverViewData?.razorpayMandateCreatedSum  || 0)?.toLocaleString("en-US")}</h4>
                                           
                                            <h5>Mandate Created</h5>
                                            </div>
                                        </div>
                                        <div className="tabs">
                                            <div className="subtabs"style={{backgroundColor:'#f4e8ff',height:window.innerWidth < 1200 ? '158px' : '144px'}}>
<div style={{display:'flex',justifyContent:'space-between',alignItems:'basline'}}>
                                            <div className="circlesIcon"style={{backgroundColor:'#bf83ff'}}><i class="fa fa-credit-card" aria-hidden="true"></i></div>
                                            <h5 className="bolder-font">{(paymentOverViewData?.razorpayMandateFailedCount || 0)?.toLocaleString("en-US")}</h5>
</div>

                                            <h4>{'₹ ' + (paymentOverViewData?.razorpayMandateFailedSum || 0)?.toLocaleString("en-US")}</h4>
                                            
                                            <h5>Mandate Failed</h5>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    {/* <div className="tableclass" style={{ marginTop: '30px' }}> */}
                                        {/* <div className="phocket-table-new" style={{ height: '290px', overflowY: 'auto' }}>
                                            <table>
                                                <tbody>
                                                    <tr className='headingTr'>
                                                        <th></th>
                                                        <th className="text-center">Total Amount</th>
                                                        <th className="text-center">Total Count</th>
                                                    </tr>
                                                    <tr >
                                                        <th className='thTagValue'>Recievable EMI</th>
                                                        <td className="text-center">{(paymentOverViewData?.receivableEmiSum)?.toLocaleString("en-US") || 0}</td>
                                                        <td className="text-center">{(paymentOverViewData?.receivableEmiCount)?.toLocaleString("en-US") || 0}</td>
                                                    </tr>
                                                    <tr >
                                                        <th className='thTagValue'>Collected Amount(Current Date)</th>
                                                        <td className="text-center">{(paymentOverViewData?.paymentReceivedSum)?.toLocaleString("en-US") || 0}</td>
                                                        <td className="text-center">{(paymentOverViewData?.paymentReceivedCount)?.toLocaleString("en-US") || 0}</td>
                                                    </tr>
                                                    <tr >
                                                        <th className='thTagValue'>Collected Amount(By Due Date)</th>
                                                        <td className="text-center">{(paymentOverViewData?.paymentReceivedByDueDateSum)?.toLocaleString("en-US") || 0}</td>
                                                        <td className="text-center">{(paymentOverViewData?.paymentReceivedByDueDateCount)?.toLocaleString("en-US") || 0}</td>
                                                    </tr>
                                                    <tr >
                                                        <th className='thTagValue'>Mandate Created</th>
                                                        <td className="text-center">{(paymentOverViewData?.razorpayMandateCreatedSum)?.toLocaleString("en-US") || 0}</td>
                                                        <td className="text-center">{(paymentOverViewData?.razorpayMandateCreatedCount)?.toLocaleString("en-US") || 0}</td>
                                                    </tr>
                                                    <tr >
                                                        <th className='thTagValue'>Mandate Failed</th>
                                                        <td className="text-center">{(paymentOverViewData?.razorpayMandateFailedSum)?.toLocaleString("en-US") || 0}</td>
                                                        <td className="text-center">{(paymentOverViewData?.razorpayMandateFailedCount)?.toLocaleString("en-US") || 0}</td>
                                                    </tr>


                                                </tbody>
                                            </table>
                                        </div> */}
                                    {/* </div> */}

                                </div>


                                <div className="weeklyCollectionSection outerBoxWithPadding">
                                    <div className="heading">
                                        <h4>Weekly Collection</h4>
                                    </div>

                                    <div className="barChart">
                                        <ResponsiveContainer width="100%" height="100%">
                                            <BarChart
                                                width={500}
                                                height={300}
                                                data={weeklyCollectionData}
                                                barSize={20}
                                                margin={{
                                                    top: 5,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5,
                                                }}
                                            >
                                                <defs>
                                                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                                        <stop offset="0%" stopColor="#8884d8" stopOpacity={1} />
                                                        <stop offset="100%" stopColor="#ffffff" stopOpacity={1} />
                                                    </linearGradient>
                                                </defs>
                                                <CartesianGrid stroke="none" />
                                                <XAxis dataKey="date" />
                                                <YAxis />
                                                <Tooltip />
                                                <Bar dataKey="amount" fill="url(#colorPv)" radius={[3,3,3,3]} />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>

                                <div className="MandateInsights outerBoxWithPadding">
                                    <div className="heading">
                                        <h4>Mandate Insights(Payments Collected)</h4>
                                    </div>
                                    <div className="row" style={{ marginTop: '30px' }}>
                                        <div className="col-md-6 col-xs-12">
                                            <div className={`boxes ${mandateInsightTab === 'RazorPay' ? 'active' : ''} outerBoxWithPadding`} onClick={() => handleMandateInsight('RazorPay')}>
                                                <h5>RazorPay</h5>
                                                {/* <h4>₹ 1,00,000</h4> */}
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xs-12">
                                            <div className={`boxes ${mandateInsightTab === 'BillDesk' ? 'active' : ''} outerBoxWithPadding`} onClick={() => handleMandateInsight('BillDesk')}>
                                                <h5>BillDesk</h5>
                                                {/* <h4>₹ 1,00,000</h4> */}
                                            </div>
                                        </div>

                                    </div>
                                    {
                                        mandateInsightTab !== '' ?
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="barchart outerBoxWithPadding">
                                                        <div className="heading">
                                                            <h3 style={{ marginTop: '0px' }}>{mandateInsightTab} Success</h3>
                                                        </div>
                                                        <ResponsiveContainer width="100%" height="100%">
                                                            <BarChart
                                                                width={500}
                                                                height={300}
                                                                data={MandateInsightSuccessData}
                                                                barSize={20}
                                                                margin={{
                                                                    top: 20,
                                                                    right: 30,
                                                                    left: 20,
                                                                    bottom: 15,
                                                                }}
                                                            >
                                                                <defs>
                                                                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                                                        <stop offset="0%" stopColor="#8884d8" stopOpacity={1} />
                                                                        <stop offset="100%" stopColor="#ffffff" stopOpacity={1} />
                                                                    </linearGradient>
                                                                </defs>
                                                                <CartesianGrid stroke="none" />
                                                                <XAxis dataKey="date" />
                                                                <YAxis />
                                                                <Tooltip />
                                                                <Bar dataKey="totalAmount" fill="url(#colorPv)" radius={[3,3,3,3]}/>
                                                            </BarChart>
                                                        </ResponsiveContainer>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="barchart outerBoxWithPadding">
                                                        <div className="heading">
                                                            <h3 style={{ marginTop: '0px' }}>{mandateInsightTab} Failure</h3>
                                                        </div>
                                                        <ResponsiveContainer width="100%" height="100%">
                                                            <BarChart
                                                                width={500}
                                                                height={250}
                                                                data={MandateInsightFailureData}
                                                                barSize={20}
                                                                margin={{
                                                                    top: 20,
                                                                    right: 30,
                                                                    left: 20,
                                                                    bottom: 10,
                                                                }}
                                                            >
                                                                <defs>
                                                                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                                                        <stop offset="0%" stopColor="#8884d8" stopOpacity={1} />
                                                                        <stop offset="100%" stopColor="#ffffff" stopOpacity={1} />
                                                                    </linearGradient>
                                                                </defs>
                                                                <CartesianGrid stroke="none" />
                                                                <XAxis dataKey="date" />
                                                                <YAxis />
                                                                <Tooltip />
                                                                <Bar dataKey="totalAmount" fill="url(#colorPv)" radius={[3,3,3,3]} />
                                                            </BarChart>
                                                        </ResponsiveContainer>
                                                    </div>
                                                </div>
                                            </div>
                                            : ''}

                                </div>

                                <div className="paymentOverviewSection outerBoxWithPadding" style={{ marginBottom: '20px' }}>
                                    <div className="heading">
                                        <h4>Payment Channel Insights</h4>
                                    </div>
                                    <div className="row" style={{ marginTop: '15px' }}>
                                        {
                                            paymentChannelAggregatedInsightsData && paymentChannelAggregatedInsightsData?.map((value,index)=>{
                                                return(
                            <div className="col-lg-3 col-md-6 col-xs-12">
                                <div className="boxes outerBoxWithPadding" style={{margin:'10px 0px'}}>
                                    <div style={{display:'flex',justifyContent:'space-between'}}>
                                    <h5>{value.mode}</h5>
                                    <h5>{value.count}</h5>
                                    </div>
                                    <h4>₹ {(value.totalAmount)?.toLocaleString("en-US")}</h4>
                                </div>
                            </div>
                                                )
                                            })
                                        }
                            {/* <div className="col-lg-3 col-md-6 col-xs-12">
                                <div className="boxes outerBoxWithPadding">
                                    <h5>Mandate</h5>
                                    <h4>₹ 1,00,000</h4>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-xs-12">
                                <div className="boxes outerBoxWithPadding">
                                    <h5>Manual</h5>
                                    <h4>₹ 1,00,000</h4>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-xs-12">
                                <div className="boxes outerBoxWithPadding">
                                    <h5>Payment Link</h5>
                                    <h4>₹ 1,00,000</h4>
                                </div>
                            </div> */}
                        </div>
                        {console.log("paymentChannelInsightsGroupData",paymentChannelInsightsGroupData)}
                                    {
                                        paymentChannelInsightsGroupData  ?
                                            <div className="tableclass" style={{ marginTop: '30px' }}>
                                                <div className="phocket-table-new" style={{ maxHeight: '580px', overflowY: 'auto' }}>
                                                    <table>
                                                        <tbody>
                                                            <tr className='headingTr'>
                                                                <th className="text-center">Date</th>
                                                                <th className="text-center">Payment Mode</th>
                                                                <th className="text-center">Total Amount</th>
                                                                <th className="text-center">Count</th>
                                                            </tr>
                                                            {/* {
                                                                paymentChannelInsightsData?.map((value, index) => {
                                                                    return (
                                                                        <tr key={index} >
                                                                            <td>{value.date}</td>
                                                                            <td className="text-center">{value.paymentMode}</td>
                                                                            <td className="text-center">{(value.totalAmount)?.toLocaleString("en-US") || 0}</td>
                                                                            <td className="text-center">{(value.count)?.toLocaleString("en-US") || 0}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            } */}
{
                                Object.keys(paymentChannelInsightsGroupData).map((date, dateIndex) => {
                                    const dateGroup = paymentChannelInsightsGroupData[date];

                                    return dateGroup.map((item, index) => (
                                        <tr key={index}>
                                            {
                                                index === 0 && (
                                                    <td rowSpan={dateGroup.length} className="text-center">
                                                        {date}
                                                    </td>
                                                )
                                            }
                                            <td className="text-center">{item.paymentMode}</td>
                                            <td className="text-center">{(item.totalAmount)?.toLocaleString("en-US") || 0}</td>
                                            <td className="text-center">{(item.count)?.toLocaleString("en-US") || 0}</td>
                                        </tr>
                                    ));
                                })
                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            : ''}
                                </div>
                            </> :
                            <div className="text-center" style={{ marginTop: '50px' }}>
                                <img src={NodataImage} alt="No Data" />
                            </div>
                    }
                </div>
            </div>
        </>
    )
}

const renderCustomLabel = ({ name, value }) => `${name}`;