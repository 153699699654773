import React, { useState } from 'react'
import ListMain from './listAdminRoles'
import SaveRole from './SaveAdminRoles'
import Loader from '../../../presentationals/Loader/Loader.component'
export default function configMain() {
    const [tabs, setabs] = useState('list')
    
    const handletabs = (type) => {
        setabs(type)
    }
  
    return (
        <>
           
            <div className="LoanUtility">

                <div className="tabs">
                    <div className={`tab ${tabs === 'list' ? 'active' : ''}`} onClick={() => handletabs('list')}>
                        <h5>List</h5>
                    </div>
                    <div className={`tab ${tabs === 'edit' ? 'active' : ''}`} onClick={() => handletabs('edit')}>
                        <h5>Add Role</h5>
                    </div>
                </div>
                <div style={{ marginTop: '50px' }}>
                    {
                        tabs === 'list' ?
                            <ListMain />
                            : ''}
                    {
                        tabs === 'edit' ?
                            <SaveRole />
                            : ''}
                </div>
            </div>
        </>
    )
}
