import React, { useState } from "react";
import MainEmp from './Employee/mainEmp'
import MainDepartment from './Department/mainDepartment'
import MainTarget from './Targetssalary/mainTargets'

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';


const TabsExpense = ({ pageViewHandler, admin }) => {
    const [viewState, setViewState] = useState('employee')


    const BackBtnStyle2 = {
        color: '#fff',
        fontWeight: '400',
        display: 'flex',
        background: '#223d64',
        cursor: 'pointer',
        padding: '12px 20px',
        'boxShadow': 'inset -4px -4px 4px 0px #1d252d'
    }


    return (
        <>
            <div className="row fixed-top" style={{ marginTop: '0px', display: 'flex', width: '101%' }}>
                <div className="" onClick={(e) => pageViewHandler(e, 'landingPage')} style={BackBtnStyle2}>
                    <ArrowBackIosNewIcon style={{ marginTop: '1px' }} />&nbsp;Back

                </div>
                <div className="" onClick={() => setViewState('employee')} style={{ borderBottom: viewState === 'employee' ? '2px solid #267DFF' : '', background: viewState === 'employee' ? '#267DFF' : '#DBE9FF', padding: viewState === 'employee' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'employee' ? '#fff' : '#000', cursor: 'pointer', width: '33.33%' }}>
                    Employees

                </div>
                <div className="" onClick={() => setViewState('department')} style={{ borderBottom: viewState === 'department' ? '2px solid #267DFF' : '', background: viewState === 'department' ? '#267DFF' : '#DBE9FF', padding: viewState === 'department' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'department' ? '#fff' : '#000', cursor: 'pointer', width: '33.33%' }}>
                    Departments

                </div>
                <div className="" onClick={() => setViewState('target')} style={{ borderBottom: viewState === 'target' ? '2px solid #267DFF' : '', background: viewState === 'target' ? '#267DFF' : '#DBE9FF', padding: viewState === 'target' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'target' ? '#fff' : '#000', cursor: 'pointer', width: '33.33%' }}>
                    Targets

                </div>

            </div>

            <div className="" style={{ marginTop: window.innerWidth > 1800 ? '0%' : '7%' }}>

                {viewState === 'employee' ?
                    <MainEmp admin={admin} />
                    : ""}
                {viewState === 'department' ?
                    <MainDepartment admin={admin}/>
                    : ""}
                {viewState === 'target' ?
                    <MainTarget admin={admin}/>
                    : ""}
            </div>
        </>
    )
}
export default TabsExpense